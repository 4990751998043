import tinycolor from "tinycolor2";

/**
 * Functions used to generate visual styles based on spheres'colors.
 * @function oneColorGradient = Generates a gradient based on a single color.
 * @function multiColorGradient = Generates a gradient based on multiple colors.
 * @function tagColor = Generates a color and background color for a tag.
 */

export function oneColorGradient(mainColorHex: string) {
  const mainColor = tinycolor(mainColorHex);

  // Generate shades of the main color
  const shade1 = mainColor.toHexString(); // Original main color
  const shade2 = mainColor.darken(10).toHexString(); // Darker shade
  const shade3 = mainColor.darken(2).setAlpha(0.9).toRgbString(); // Darker with alpha
  const shade4 = mainColor.lighten(4).setAlpha(0.4).toRgbString(); // Lighter with alpha

  // Construct the gradient
  const gradient = `linear-gradient(145deg, ${shade1} 16%, ${shade2} 32%, ${shade3} 64%, ${shade4} 96%)`;
  return gradient;
}

export function multiColorGradient(colorHexArray: string[]) {
  if (colorHexArray.length === 0) return "";

  // Generate gradient stops for each color in the array
  const gradientStops = colorHexArray.map((colorHex, index) => {
    const color = tinycolor(colorHex);
    const lightColor = color.lighten(6).setAlpha(0.8).toRgbString(); // Lighten and set alpha
    const darkColor = color.darken(6).setAlpha(0.6).toRgbString(); // Darken and set alpha
    const position = (index / (colorHexArray.length - 1)) * 100; // Calculate position

    return `${darkColor} ${position}%, ${lightColor} ${position + 5}%`;
  });

  // Join gradient stops into a linear-gradient string
  const gradient = `linear-gradient(145deg, ${gradientStops.join(", ")})`;
  return gradient;
}

export function tagColor(color: string) {
  const mainColor = tinycolor(color);
  // Generate a lighter background color
  const backgroundColor = mainColor.setAlpha(0.1).toRgbString();

  return {
    color: mainColor.toHexString(),
    backgroundColor: backgroundColor,
  };
}
