import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DeletePeopleDocument,
  useGetPeopleQuery,
} from "../../../generated/graphql";
import { getCookie } from "../../hooks/cookie";
import {
  Alert,
  Button,
  Card,
  Collapse,
  CollapseProps,
  Form,
  Input,
  List,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import {
  displayPhoneNumber,
  handlePhoneFormat,
  renderPhone,
} from "../../hooks/formats";
import { tagColor } from "../../hooks/visual";
import {
  CommentOutlined,
  CompassOutlined,
  DeleteOutlined,
  EditOutlined,
  HeartFilled,
  MailOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import { useMutation } from "@apollo/client";

const DisplayPerson = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { name } = useParams();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const { loading, error, data, refetch } = useGetPeopleQuery({
    variables: {
      where: {
        personId: state.personId,
        personName: name,
        personCreatedBy: {
          userId: loggedUserId,
        },
      },
    },
  });
  const [deletePeople] = useMutation(DeletePeopleDocument);
  const person = data?.people[0];
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    refetch();
  }, [name]);

  if (loading) {
    return <Spin tip="Loading..." />;
  }
  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  const displayPhone = (phone: string) => {
    const personPhoneNumber = handlePhoneFormat(phone).phoneNumber;
    const personCountryCode = handlePhoneFormat(phone).countryCode;
    const personAreaCode = handlePhoneFormat(phone).areaCode;

    if (
      !personPhoneNumber.length ||
      !personCountryCode ||
      !personAreaCode.length
    ) {
      return (
        <Button
          type="default"
          onClick={() => {
            navigate("/person-form/update", {
              state: {
                sphereIds: person?.personSpheres.map(
                  (sphere) => sphere.sphereId
                ),
                personData: { ...person },
              },
            });
          }}
        >
          Add Phone Number
        </Button>
      );
    }

    return displayPhoneNumber(
      personPhoneNumber,
      personCountryCode,
      personAreaCode
    );
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <p>
          <UserOutlined /> <b>General Information</b>
        </p>
      ),
      children: person && (
        <Card>
          {person.personName && <p>Name: {person.personName}</p>}
          {person.personPrimaryEmail && (
            <p>Email: {person.personPrimaryEmail}</p>
          )}
          {person.personPrimaryPhone && (
            <p>Primary Phone: {displayPhone(person.personPrimaryPhone)}</p>
          )}
          {person.personBirthDate && (
            <p>Birth Date: {person.personBirthDate}</p>
          )}
          {person.personJobTitle && <p>Job Title: {person.personJobTitle}</p>}
          {person.personDescription && (
            <p>Description: {person.personDescription}</p>
          )}
        </Card>
      ),
    },
    {
      key: "2",
      label: (
        <p>
          <CompassOutlined /> <b>Platforms</b>
        </p>
      ),
      children: (
        <Card>
          <List
            className="list"
            itemLayout="horizontal"
            dataSource={person?.personPlatforms}
            renderItem={(platform) => (
              <List.Item>
                {platform.platformName}{" "}
                <Button
                  type="link"
                  href={
                    platform?.platformUrl ? platform?.platformUrl : undefined
                  }
                >
                  Contact Link
                </Button>
                {platform.platformFavorite ? (
                  <Tooltip title="Preferred way of communication">
                    <HeartFilled
                      style={{ color: "#ff5d8f", fontSize: "20px" }}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </List.Item>
            )}
          />
        </Card>
      ),
    },
    {
      key: "3",
      label: (
        <p>
          <CommentOutlined /> <b>Other Contact Method</b>
        </p>
      ),
      children: (
        <>
          <Card>
            <h3>
              <PhoneOutlined /> Phone Numbers
            </h3>
            <List
              className="list"
              itemLayout="horizontal"
              dataSource={person?.personPhones}
              renderItem={(phone) => (
                <List.Item>
                  {phone.phoneType} {renderPhone(phone.phoneNumber)}
                </List.Item>
              )}
            />
          </Card>
          <Card style={{ marginTop: "16px" }}>
            <h3>
              <MailOutlined /> Emails
            </h3>
            <List
              className="list"
              itemLayout="horizontal"
              dataSource={person?.personEmails}
              renderItem={(email) => (
                <List.Item>
                  {email.emailType} {email.emailAddress}
                </List.Item>
              )}
            />
          </Card>
        </>
      ),
    },
    {
      key: "4",
      label: (
        <p>
          <TeamOutlined /> <b>Spheres</b>
        </p>
      ),
      children: (
        <Card>
          <List
            className="list"
            itemLayout="horizontal"
            dataSource={person?.personSpheres}
            renderItem={(sphere) => (
              <List.Item className="sphere-list-item">
                <span
                  className="tag"
                  style={tagColor(
                    sphere.sphereColor ? sphere.sphereColor : "#1677ff"
                  )}
                >
                  {sphere.sphereName}
                </span>
              </List.Item>
            )}
          />
        </Card>
      ),
    },
    {
      key: "5",
      label: (
        <p style={{ paddingRight: "16px" }}>
          <DeleteOutlined /> <b>Delete Person Permanently</b>
        </p>
      ),
      children: (
        <Card>
          <div>
            <h2>Delete Person Permanently</h2>
            <p>
              You are going to delete this person <b>permanently</b>.
            </p>
            <Form
              layout="vertical"
              onFinish={async (values) => {
                if (
                  name &&
                  values.personName.toUpperCase() !== name.toUpperCase()
                ) {
                  setErrorMessage("Wrong name entered. Please try again.");
                } else if (
                  name &&
                  values.personName.toUpperCase() === name.toUpperCase() &&
                  person
                ) {
                  try {
                    await deletePeople({
                      variables: {
                        where: {
                          personId: person.personId,
                          personName: name,
                        },
                      },
                    });
                    setOpenModal(true);
                  } catch (error: any) {
                    setErrorMessage(error.message);
                  }
                }
              }}
            >
              <FormItem
                label={
                  <p>
                    Please enter the following name:{" "}
                    <b>{name?.toUpperCase()}</b> to confirm the permanent
                    delete:
                  </p>
                }
                name="personName"
                rules={[
                  { required: true, message: "Please enter Person's name" },
                ]}
              >
                <Input
                  placeholder="Enter Person's name"
                  onChange={() => setErrorMessage("")}
                />
              </FormItem>
              <Form.Item>
                <Button type="primary" htmlType="submit" danger>
                  CONFIRM <DeleteOutlined />
                </Button>
                {errorMessage && (
                  <p
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
              </Form.Item>
            </Form>
          </div>
        </Card>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "100%",
          background:
            "linear-gradient(145deg, rgba(144,144,144,1) 16%, rgba(109,109,109,1) 40%, rgba(114,114,114,0.9192051820728291) 72%, rgba(144,144,144,0.3981967787114846) 96%)",
          color: "white",
          padding: "16px 8px",
          margin: "0px 0px 16px 0px",
          borderRadius: "8px",
        }}
      >
        <h1>Person Account: {person?.personName}</h1>
      </div>
      <Button onClick={() => navigate(-1)} style={{ marginRight: "16px" }}>
        Back
      </Button>
      {state.personId && (
        <Button
          type="primary"
          onClick={() => {
            navigate("/person-form/update", {
              state: {
                sphereIds: person?.personSpheres.map(
                  (sphere) => sphere.sphereId
                ),
                personData: { ...person },
              },
            });
          }}
        >
          Edit <EditOutlined />
        </Button>
      )}
      <Collapse
        items={items}
        defaultActiveKey={["1"]}
        style={{ margin: "24px" }}
      />
      <Modal
        title={name?.toUpperCase() + " has been removed from your database"}
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
          window.location.href = "/";
        }}
        footer={null}
      ></Modal>
    </>
  );
};

export default DisplayPerson;
