/**
 * Navigation bar
 * @description links: All People, Spheres (query that gets all logged user's spheres)(update + delete each sphere), Add Sphere
 * You can select multiple spheres to display people from those spheres
 */

import { useEffect, useState } from "react";
import {
  TeamOutlined,
  PlusCircleOutlined,
  EditOutlined,
  HomeOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu, Button, Checkbox } from "antd";
import Sider from "antd/es/layout/Sider";
import { Link, useNavigate } from "react-router-dom";
import SphereForm from "../pages/spheres/SphereForm";
import { useGetUsersSpheresQuery } from "../../generated/graphql";
import { getCookie } from "../hooks/cookie";
import { LogoutBtn } from "../reusable-components/LogoutBtn";

const Navbar = () => {
  const [isSphereFormVisible, setIsSphereFormVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [hoveredSphereId, setHoveredSphereId] = useState<
    null | string | boolean
  >(null);
  const [action, setAction] = useState<"create" | "edit" | null>(null);
  const [selectedNavKey, setSelectedNavKey] = useState("home");
  const [selectedSpheres, setSelectedSpheres] = useState<
    { sphereId: string; sphereName: string; sphereColor: string }[]
  >([]);
  const [sphereToUpdate, setSphereToUpdate] = useState<
    | {
        sphereId: string;
        sphereName: string;
        sphereColor: string;
      }
    | undefined
  >(undefined);
  const navigate = useNavigate();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;

  const {
    data = {
      users: [
        { userSpheres: [{ sphereId: "", sphereName: "", sphereColor: "" }] },
      ],
    },
    refetch,
  } = useGetUsersSpheresQuery({
    variables: {
      where: { userId: loggedUserId },
    },
  });

  useEffect(() => {
    if (data) {
      setSelectedSpheres((prevSelectedSpheres) => {
        const updatedSelectedSpheres = prevSelectedSpheres.map((prevSphere) => {
          const matchingSphere = data.users[0].userSpheres.find(
            (dataSphere) => dataSphere.sphereId === prevSphere.sphereId
          );
          if (
            matchingSphere &&
            matchingSphere.sphereName !== prevSphere.sphereName
          ) {
            return {
              ...prevSphere,
              sphereName: matchingSphere.sphereName,
            };
          }
          return prevSphere;
        });
        return updatedSelectedSpheres;
      });
    }
  }, [data]);

  useEffect(() => {
    if (selectedSpheres.length) {
      navigate("/people-list", { state: { selectedSpheres: selectedSpheres } });
    } else if (
      selectedSpheres.length === 0 &&
      selectedNavKey !== "home" &&
      selectedNavKey !== "userAccount"
    ) {
      navigate("/");
      setSelectedNavKey("home");
    }
  }, [selectedSpheres, data]);

  const handleCheckboxChange = (
    checked: boolean,
    sphere: { sphereId: string; sphereName: string; sphereColor: string }
  ) => {
    setSelectedSpheres((prevSelectedSpheres) => {
      const updatedSpheres = checked
        ? [...prevSelectedSpheres, sphere]
        : prevSelectedSpheres.filter(
            (item) => item.sphereId !== sphere.sphereId
          );

      return updatedSpheres;
    });
  };

  const handleItemClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    sphere: { sphereId: string; sphereName: string; sphereColor: string }
  ) => {
    // Prevent the event from propagating if clicking on the button or checkbox
    if (
      (e.target as HTMLElement).closest(".ant-btn") ||
      (e.target as HTMLElement).closest(".ant-checkbox-wrapper")
    ) {
      return;
    }
    handleCheckboxChange(
      !selectedSpheres.some((item) => item.sphereId === sphere.sphereId),
      sphere
    );
  };

  const truncateText = (text: string, maxLength: number): string => {
    const capitalizeFirstLetter = (str: string): string => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (text.length > maxLength) {
      return capitalizeFirstLetter(text.slice(0, maxLength)) + "...";
    }
    return capitalizeFirstLetter(text);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      collapsedWidth="0"
      breakpoint="lg"
    >
      <Menu
        theme="dark"
        defaultSelectedKeys={["home"]}
        selectedKeys={[selectedNavKey]}
        mode="inline"
      >
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link
            to="/"
            onClick={() => {
              setSelectedSpheres([]);
              setSelectedNavKey("home");
            }}
          >
            All People
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          key="spheres-header"
          icon={<TeamOutlined />}
          title="Spheres"
        >
          {data?.users[0]?.userSpheres
            .slice()
            .sort((a, b) => a.sphereName.localeCompare(b.sphereName))
            .map((item) => (
              <Menu.Item
                key={item.sphereId}
                onMouseEnter={() => setHoveredSphereId(item.sphereId)}
                onMouseLeave={() => setHoveredSphereId(false)}
                style={{ paddingLeft: "8px" }}
              >
                <div
                  className="navbar-item"
                  onClick={(e) => {
                    handleItemClick(e, item);
                    setSelectedNavKey(item.sphereId);
                  }}
                >
                  <Checkbox
                    checked={selectedSpheres.some(
                      (sphere) => sphere.sphereId === item.sphereId
                    )}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, {
                        sphereId: item.sphereId,
                        sphereName: item.sphereName,
                        sphereColor: item.sphereColor
                          ? item.sphereColor
                          : "#1677ff",
                      })
                    }
                    style={{ color: "white", padding: "8px 0px" }}
                  >
                    {truncateText(item.sphereName, 10)}
                  </Checkbox>
                  <Button
                    style={
                      hoveredSphereId === item.sphereId
                        ? { opacity: 1 }
                        : { opacity: 0 }
                    }
                    type="link"
                    onClick={() => {
                      setAction("edit");
                      setIsSphereFormVisible(true);
                      setSphereToUpdate({
                        sphereId: item.sphereId,
                        sphereName: item.sphereName,
                        sphereColor: item.sphereColor,
                      });
                      setCollapsed(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "#3a86ff",
                        padding: "8px",
                        borderRadius: "4px",
                      }}
                    />
                  </Button>
                </div>
              </Menu.Item>
            ))}
        </Menu.SubMenu>
        <Menu.Item key="add-sphere" icon={<PlusCircleOutlined />}>
          <Button
            type="text"
            onClick={() => {
              setAction("create");
              setIsSphereFormVisible(true);
              setCollapsed(true);
            }}
            style={{ color: "white" }}
          >
            Add Sphere
          </Button>
        </Menu.Item>
        <Menu.Item key="userAccount" icon={<UserOutlined />}>
          <Link
            to="/my-account"
            onClick={() => {
              setSelectedSpheres([]);
              setSelectedNavKey("userAccount");
            }}
          >
            My Account
          </Link>
        </Menu.Item>
        <Menu.Item style={{ pointerEvents: "none" }}></Menu.Item>
        <Menu.Item style={{ pointerEvents: "none" }}></Menu.Item>
        <Menu.Item
          key="logout"
          icon={<PoweroffOutlined />}
          className="responsive-menu-item"
        >
          <LogoutBtn location="navbar" className="logout-nav" />
        </Menu.Item>
      </Menu>
      {isSphereFormVisible && (
        <SphereForm
          visible={isSphereFormVisible}
          onCancel={(param?: any) => {
            setIsSphereFormVisible(false);
            if (action === "create" && param.sphereId) {
              refetch();
              setSelectedSpheres([...selectedSpheres, param]);
            }
            if (action === "edit" && param.sphereId) {
              refetch();
              setSelectedSpheres(
                selectedSpheres.map((sphere) =>
                  sphere.sphereId === param.sphereId ? param : sphere
                )
              );
            }
            if (action === "edit" && param === "deleted") {
              refetch();
              setSelectedSpheres(
                selectedSpheres.filter(
                  (sphere) => sphere.sphereId !== sphereToUpdate?.sphereId
                )
              );
            }
          }}
          action={action}
          sphere={sphereToUpdate}
        />
      )}
    </Sider>
  );
};

export default Navbar;
