import { HeartFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Input, InputRef, Space, TableColumnType, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { tagColor } from "../../../hooks/visual";
import { renderPhone } from "../../../hooks/formats";

type DataIndex = keyof SimplifiedPerson;

/**
 * Interface to display people in a simplified way because Person type includes too many information.
 */
interface SimplifiedPerson {
  key: number;
  personId: string;
  personName: string;
  personPrimaryEmail: string;
  personPrimaryPhone: string;
}

/**
 * Used by Ant Design Table component.
 * @returns List of columns to display in PeopleList and AllPeople components.
 * IMPORTANT TO NOTE:
 * - Columns are displayed in the order they are listed.
 * - Columns can be hidden or shown by changing the hidden property.
 * - If you want to add a new column, you must add it to the dataSource function present in each lists components (AllPeople.tsx and PeopleList.tsx).
 */

export const Columns = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  /**Used by list column filters:  getColumnSearchProps()*/
  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  /**List columns display filter */
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<SimplifiedPerson> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                setSearchText("");
                confirm();
              }
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "personPrimaryPhone") {
        const sanitizedValue = (value as string)
          .replace(/\s/g, "")
          .replace(/[^0-9]/g, "");
        const sanitizedRecord = (record[dataIndex] as string)
          .replace(/\s/g, "")
          .replace(/[^0-9]/g, "");
        return sanitizedRecord.includes(sanitizedValue);
      } else {
        return (
          record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()) ?? false
        );
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const list: TableColumnType<any>[] = [
    {
      title: "Name",
      fixed: "left",
      dataIndex: "personName",
      key: "personName",
      ...getColumnSearchProps("personName"),
      width: "6vw",
    },
    {
      title: "Email",
      dataIndex: "personPrimaryEmail",
      key: "personPrimaryEmail",
      ...getColumnSearchProps("personPrimaryEmail"),
      hidden: false,
      width: "10vw",
    },
    {
      title: "Phone",
      dataIndex: "personPrimaryPhone",
      key: "personPrimaryPhone",
      ...getColumnSearchProps("personPrimaryPhone"),
      render: (text, record) => renderPhone(record.personPrimaryPhone),
      hidden: false,
      width: "10vw",
    },
    {
      title: "Spheres",
      key: "personSpheres",
      render(value, record, index) {
        return (
          <Space
            size="middle"
            style={{ maxWidth: "20vw", display: "flex", flexWrap: "wrap" }}
          >
            {record.personSpheres ? (
              <>
                {record.personSpheres.map((sphere: any) => {
                  return (
                    <span
                      key={sphere.sphereId}
                      className="tag"
                      style={tagColor(
                        sphere.sphereColor ? sphere.sphereColor : "#1677ff"
                      )}
                    >
                      {sphere.sphereName}
                    </span>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </Space>
        );
      },
      hidden: false,
      width: "10vw",
    },
    {
      title: "Platforms",
      key: "personPlatforms",
      hidden: true,
      render(value, record, index) {
        return (
          <Space
            size="middle"
            style={{ maxWidth: "20vw", display: "flex", flexWrap: "wrap" }}
          >
            {record.personPlatforms ? (
              <>
                {record.personPlatforms.map((platform: any) => {
                  return (
                    <Link
                      to={platform.platformUrl}
                      target="_blank"
                      key={platform.platformId}
                    >
                      <Button type="default">
                        <Tooltip title="Open link">
                          {platform.platformName}
                        </Tooltip>
                        {platform.platformFavorite ? (
                          <Tooltip title="Preferred way of communication">
                            <HeartFilled
                              style={{
                                color: "#ff5d8f",
                                fontSize: "20px",
                                paddingLeft: "8px",
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </Button>
                    </Link>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </Space>
        );
      },
      width: "10vw",
    },
    {
      title: "Other Phones",
      key: "personPhones",
      hidden: true,
      render(value, record, index) {
        return (
          <Space
            size="middle"
            style={{ maxWidth: "20vw", display: "flex", flexWrap: "wrap" }}
          >
            {record.personPhones ? (
              <>
                {record.personPhones.map((phone: any) => {
                  return (
                    <Tooltip title="Call">
                      {phone.phoneType} {renderPhone(phone.phoneNumber)}
                    </Tooltip>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </Space>
        );
      },
      width: "10vw",
    },
    {
      title: "Other Emails",
      key: "personEmails",
      hidden: true,
      render(value, record, index) {
        return (
          <Space
            size="middle"
            style={{ maxWidth: "20vw", display: "flex", flexWrap: "wrap" }}
          >
            {record.personEmails ? (
              <>
                {record.personEmails.map((email: any) => {
                  return (
                    <p>
                      {email.emailType} {email.emailAddress}
                    </p>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </Space>
        );
      },
      width: "10vw",
    },
    {
      title: "Birthdate",
      dataIndex: "personBirthDate",
      key: "personBirthDate",
      hidden: true,
      width: "10vw",
    },
  ];
  return list;
};
